<!--物流配送-->
<template>
    <div class="ReturnGoods">
        <div v-if="this.language==='zh_CN'">
            <h3>物流配送：</h3>
            <p>1) 将货物送至指定地址；</p>
            <p>2）送货至选定的立陶宛代表处：维尔纽斯、考纳斯、克莱佩达、希奥利艾、马里扬波尔等公司代表处所在城市；</p>
            <p>3) 运送到其他国家。</p>

            <h3>交货条件：</h3>
            <p>所有商品均通过快递服务送达订单信息中注明的地址。 如果产品在工作日 13:00 之前订购，产品将在同一工作日发货。
                如果在 13:00 之后订购商品，该商品将在下一个工作日发货。 I-V 中午 12 点之前接受订单。
                周末及节假日不接受订单，不发货。</p>
            <p>立陶宛的交货时间：1-3 天。 从收到货物订单的那一刻起。</p>
            <p>对于立陶宛共和国以外的地区，请联系 eu-info@foherb.com</p>

            <h5>重要提示：请注意，如果您选择通过银行转账付款，您订购的商品将在款项存入我们的账户后才会发货。</h5>
            <p>
                如果您订购的商品在商店缺货，我们将与您联系并通知您交货时间可能会更长。 最长交货时间不应超过 3 周。 如果货物是根据
                订单，我们将单独约定交货日期。 个别物品的交货时间可能有所不同。
            </p>
            <p>
                在货物交付过程中，有必要检查包裹的状况。 如果您在交付过程中发现违规行为，您有权不接受包裹。
                收到包裹后，视为包裹已送达且没有损坏。
            </p>
            <p>
                如果指定的送货数据不正确（收件人姓名、地址、邮政编码、联系电话），订单的送货日期可能会发生变化。
                如果交货细节有变，请尽快通知我们电子邮件：eu-info@foherb.com
            </p>
            <p>
                有关货物交付的更多信息，您可以通过电话获得。 +37066137436 或通过电子邮件：eu-info@foherb.com
            </p>
            <p>
                您的货物交付由运输公司 Linkera Group UAB 执行，该公司在立陶宛和欧洲国家提供快递服务。
            </p>
            <p>
                LLC“Linkera Group”的使命 - 准时且有保证！
            </p>
        </div>
        <div v-else-if="this.language==='he_IL'">
            <h3>דרכי מסירה:</h3>
            <p>1) מסירת מוצר בכתובת שצוינה</p>
            <p>2) מסירת מוצר באחד מסניפי החברה בליטא: וילנה, קובנה, קלייפדה, שיאוליאי, מריאמפולה או עיר אחרת בה קיים סניף החברה; </p>
            <p>3) מסירה במדינות אחרות.</p>

            <h3>תנאי מסירה:</h3>
            <p>כל המוצרים נמסרים ע"י שירות שליחויות בכתובת המצוינת בפרטי ההזמנה. מוצר המוזמן בימי עבודה לא יאוחר מהשעה 13:00 יישלח באותו יום עבודה. מוצר המוזמן לאחר השעה 13:00 יישלח ביום העבודה הבא. ההזמנות מתקבלות בימים א'-ה' עד השעה 12:00. אין קבלת הזמנות ומסירת מוצרים בסופי שבוע ובימי חג.</p>
            <p>מסירה בשטח ליטא: 1-3 ימים מרגע קבלת הזמנת מוצר.</p>
            <p>לקבלת פרטים אודות מסירה מחוץ לגבולות רפובליקת ליטא נא לפנות בדוא"ל  eu-info@foherb.com</p>

            <h5>לתשומת ליבכם: במידה ובחרתם בתשלום באמצעות העברה בנקאית, המוצרים המוזמנים יישלחו אך ורק לאחר קליטת התשלום בחשבוננו</h5>
            <p>
              במידה והמוצר המוזמן על ידיכם חסר במלאי החנות, ניצור עימכם קשר ונעדכן שייתכן עיכוב במסירה. פרק הזמן המירבי עד למסירה לא יעלה על 3 שבועות. במידה והמוצר מסופק במסגרת הזמנה מיוחדת, מועד המסירה יתואם בנפרד. ייתכן שוני בין מועדי מסירה של מוצרים מסוימים.
            </p>
            <p>
              במעמד מסירת המוצר יש לבדוק את מצב החבילה. במידה והבחנתם בנזק, זכותכם שלא לקבל את החבילה. בקבלת החבילה הנכם מאשרים שהחבילה נמסרה ללא נזקים.
            </p>
            <p>
              במידה ונפלה טעות בציון פרטי מסירה (שם המקבל, כתובת, מיקוד, טלפון ליצירת קשר) מועד המסירה עלול להשתנות. במקרה של שינוי בפרטי המסירה אנא עדכנו אותנו על כך בהקדם האפשרי בדוא"ל: eu-info@foherb.com
            </p>
            <p>
              מידע מפורט יותר בנושא מסירת מוצר ניתן לקבל בטל' +37066137436 או בדוא"ל e-mail: eu-info@foherb.com
            </p>
            <p>
              מסירת המוצר שלכם מתבצעת ע"י חב' תובלה  Linkera Group UAB אשר מעניקה שירותי משלוח מהיר ברחבי ליטא ובמדינות אירופה
            </p>
            <p>
              משימת  Linkera Group בע"מ - בזמן ובאחריות
            </p>
        </div>
        <div v-else-if="this.language==='lt_LT'">
            <h3>PREKIŲ PRISTATYMO BŪDAI:</h3>
            <p>1) Prekių pristatymas nurodytu adresu;</p>
            <p>2) Prekių pristatymas į pasirinktą kompanijos atstovybę Lietuvoje: Vilniuje, Kaune, Klaipėdoje,
                Šiauliuose, Marijampolėje ir kituose miestuose, kuriuose yra įsikūrusios kompanijos atstovybės;</p>
            <p>3) Siuntimas į kitas šalis.</p>

            <h3>PRISTATYMO SĄLYGOS IR TERMINAI:</h3>
            <p>Visas prekes pristato kurjerių tarnyba Jūsų užsakymo informacijoje nurodytu adresu. Jei prekės užsakomos
                darbo dienomis iki 13 val., prekės išsiunčiamos tą pačią darbo dieną. Jei prekės užsakomos po 13 val.,
                prekės išsiunčiamos kitą darbo dieną. Penktadieniais užsakymai priimami iki 12 val. Savaitgaliais ir
                švenčių dienomis užsakymai nepriimami ir prekės nepristatomos.</p>
            <p>Pristatymo laikas Lietuvoje: 1 - 3 d. d. nuo prekių užsakymo gavimo.</p>
            <p>Dėl pristatymo už Lietuvos Respublikos ribų galite teirautis eu-info@foherb.com</p>

            <h5>SVARBU: Atkreipiame dėmesį, kad pasirinkus mokėjimą pavedimu, Jūsų užsakytos prekės bus išsiųstos tik po
                pinigų gavimo į mūsų sąskaitą.</h5>
            <p>
                Jei Jūsų užsakytos prekės parduotuvėje nėra, mes su Jumis susisieksime ir informuosime, kad pristatymas
                gali užtrukti ilgiau. Maksimali pristatymo trukmė neturėtų viršyti 3 savaičių. Jei prekė pristatoma
                pagal spec. užsakymą, pristatymo terminą suderinsime atskirai. Atskirų prekių pristatymo terminai gali
                skirtis.
            </p>
            <p>
                Prekių pristatymo metu Jūs privalote patikrinti siuntos būklę. Pastebėję siuntos pažeidimą, turite teisę
                siuntos nepriimti. Priėmus siuntą, laikoma, kad siunta buvo pristatyta be pažeidimų.
            </p>
            <p>
                Nurodžius neteisingus siuntos pristatymo duomenis (gavėjo vardas, pavardė, adresas, pašto kodas,
                kontaktinis telefono numeris), gali keistis užsakymo pristatymo terminas. Pasikeitus pristatymo
                duomenims, prašome kuo skubiau mus informuoti el. paštu eu-info@foherb.com
            </p>
            <p>
                Daugiau informacijos apie prekių pristatymą galite gauti tel. +37066137436 arba el. paštu
                eu-info@foherb.com
            </p>
            <p>
                Jūsų prekių pristatymu rūpinasi transporto kompanija UAB „Linkera Group“ teikianti skubių siuntų
                pristatymo paslaugas Lietuvoje ir Europos šalyse.
            </p>
            <p>
                UAB „Linkera Group“ misija – laiku ir patikinimai!
            </p>
        </div>
        <div v-else-if="this.language==='ru_RU'">
            <h3>СПОСОБЫ ДОСТАВКИ:</h3>
            <p>1) Доставка товара по указанному адресу;</p>
            <p>2) Доставка товара в выбранное представительство компании в Литве: Вильнюс, Каунас, Клайпеда, Шяуляй,
                Мариямполе и другие города, где расположены представительства компании;</p>
            <p>3) Доставка в другие страны.</p>

            <h3>УСЛОВИЯ ДОСТАВКИ:</h3>
            <p>Все товары доставляются курьерской службой по адресу, указанному в информации о заказе. Если товар
                заказывается в рабочие дни до 13:00, товар отправляется в тот же рабочий день. Если товар заказывается
                после 13:00, товар отправляется на следующий рабочий день. Заказы принимаются до 12 часов I-V. Заказы не
                принимаются и товар не доставляется в выходные и праздничные дни.</p>
            <p>Срок доставки по Литве: 1-3 дня. д. с момента получения заказа товара.</p>
            <p>О доставке за пределы Литовской Республики можно узнать по адресу eu-info@foherb.com</p>

            <h5>ВАЖНО: Обратите внимание, что если вы выберете оплату банковским переводом, заказанные вами товары будут
                отправлены только после поступления денег на наш счет.</h5>
            <p>
                Если заказанного вами товара нет в наличии в магазине, мы свяжемся с вами и сообщим, что доставка может
                занять больше времени. Максимальный срок доставки не должен превышать 3 недель. Если товар поставляется
                по спец. Заказ, мы согласуем дату доставки отдельно. Сроки доставки отдельных товаров могут отличаться.
            </p>
            <p>
                Во время доставки товара необходимо проверить состояние посылки. Вы имеете право не принимать посылку,
                если заметите нарушение при доставке. При получении посылки считается, что посылка доставлена без
                повреждений.
            </p>
            <p>
                В случае указания некорректных данных доставки (ФИО получателя, адрес, почтовый индекс, контактный
                телефон) дата доставки заказа может измениться. Если данные о доставке изменились, пожалуйста, сообщите
                нам как можно скорее e-mail: eu-info@foherb.com
            </p>
            <p>
                Более подробную информацию о доставке товара Вы можете получить по тел. +37066137436 или по e-mail:
                eu-info@foherb.com
            </p>
            <p>
                Доставкой вашего товара занимается транспортная компания Linkera Group UAB, которая предоставляет услуги
                экспресс-доставки по Литве и странам Европы.
            </p>
            <p>
                Миссия OOО "Linkera Group" - вовремя и с гарантиями!
            </p>
        </div>
        <div v-if="this.language==='ka_GE'">
        <h3>მიწოდების მეთოდები:</h3>
        <p>1) საქონლის მიწოდება მითითებულ მისამართზე;</p>
        <p>2) საქონლის მიწოდება კომპანიის
          საქართველოს შერჩეულ წარმომადგენლობაში</p>
        <p>3) მიწოდება სხვა ქვეყნებში.</p>

        <h3>მიწოდების პირობები</h3>
        <p>ყველა პროდუქტი მიწოდებულია კურიერის
          საშუალებით შეკვეთის ინფორმაციაში მითითებულ
          მისამართზე. თუ საქონელი შეკვეთილია სამუშაო
          დღეებში 13:00 საათამდე, საქონელი იგზავნება იმავე
          სამუშაო დღეს. თუ ნივთი შეკვეთილია 13:00 საათის
          შემდეგ, საქონელი გაიგზავნება მომდევნო სამუშაო
          დღეს. შეკვეთები მიიღება I-V 12 საათამდე. შეკვეთები
          არ მიიღება და პროდუქციის მიწოდება არ ხდება
          შაბათ-კვირას და არდადეგებზე.</p>
        <p>მიწოდების დრო საქართველოში: 1-3 დღე.
          საქონლის შეკვეთის მიღების მომენტიდან.</p>
        <p>საქართველოს ფარგლებს გარეთ
          მიწოდების შესახებ ინფორმაციისთვის გთხოვთ,
          დაგვიკავშირდეთ uabkordicepsas@gmail.com</p>
        <h5>მნიშვნელოვანია: გთხოვთ, გაითვალისწინოთ,
          რომ თუ გადაწყვეტთ გადახდას საბანკო
          გადარიცხვით, თქვენს მიერ შეკვეთილი
          პროდუქტები გაიგზავნება მხოლოდ მას შემდეგ,
          როცა თანხა ჩვენს ანგარიშზე ჩაირიცხება.</h5>
        <p>თუ თქვენ მიერ შეკვეთილი საქონელი ამჟამად
          არ არის კომპანიაში, ჩვენ დაგიკავშირდებით,
          რათა გაცნობოთ, რომ მიწოდებას შეიძლება
          მეტი დრო დასჭირდეს. მიწოდების მაქსიმალური
          დრო არ უნდა აღემატებოდეს 3 კვირას.
          თუ პროდუქტი მიეწოდება სპეციალურ.
          შეუკვეთეთ, მიწოდების თარიღზე ცალკე შევთანხმდებით.
          ცალკეული პროდუქტებისთვის მიწოდების
          დრო შეიძლება განსხვავდებოდეს.
        </p>
        <p>საქონლის მიტანისას აუცილებელია ამანათის
          მდგომარეობის შემოწმება. თქვენ გაქვთ უფლება
          არ მიიღოთ ამანათი, თუ მიტანის დროს დარღვევას
          შეამჩნევთ. ამანათის მიღებისას ამანათი ითვლება
          დაუზიანებლად ჩაბარებულად.
        </p>
        <p>თუ თქვენ მიუთითებთ არასწორ მიწოდების
          ინფორმაციას (მიმღების სრული სახელი, მისამართი,
          საფოსტო კოდი, საკონტაქტო ტელეფონის ნომერი),
          შეკვეთის მიწოდების თარიღი შეიძლება შეიცვალოს.
          თუ მიწოდების დეტალები შეიცვალა, გთხოვთ
          შეგვატყობინოთ რაც შეიძლება მალე
          ელ. ფოსტა: uabkordicepsas@gmail.com
        </p>
        <p>საქონლის მიწოდების შესახებ უფრო დეტალური
          ინფორმაციის მიღება შეგიძლიათ ტელეფონით:
          +37060296453 ან ელექტრონული ფოსტით:
          uabkordicepsas@gmail.com
        </p>
        <p>თქვენი საქონლის მიწოდებას ახორციელებს
          სატრანსპორტო კომპანია Linkera Group UAB,
          რომელიც გთავაზობთ ექსპრეს მიწოდების
          მომსახურებას ლიტვისა და ევროპის ქვეყნებში.
        </p>
        <p>შპს Linkera Group-ის მისია არის დროულად
          და გარანტიებით!
        </p>
      </div>
        <div v-else>
            <h3>METHODS OF DELIVERY OF GOODS:</h3>
            <p>1) Delivery of goods to the specified address;</p>
            <p>2) Delivery of goods to the selected representative office of the company in Lithuania: Vilnius, Kaunas,
                Klaipėda, Šiauliai, Marijampole and other cities where the representative offices of the company are
                located;</p>
            <p>3) Shipping to other countries.</p>

            <h3>DELIVERY TERMS AND TERMS:</h3>
            <p>All goods are delivered by the courier service to the address specified in your order information. If the
                goods are ordered on working days before 13:00, the goods are dispatched on the same working day. If the
                goods are ordered after 13:00, the goods are dispatched on the next working day. Orders are accepted
                until 12:00 noon on Fridays. Orders are not accepted and goods are not delivered on weekends and
                holidays.</p>
            <p>Delivery time in Lithuania: 1 - 3 days. d. from the receipt of the goods order.</p>
            <p>You can inquire about delivery outside the Republic of Lithuania at eu-info@foherb.com</p>

            <h5>IMPORTANT: Please note that if you choose to pay by bank transfer, your ordered goods will be sent only
                after receiving the money in our account.</h5>
            <p>
                If the product you ordered is not available in the store, we will contact and inform you that the
                delivery may take longer. The maximum delivery time should not exceed 3 weeks. If the product is
                delivered according to spec. order, we will coordinate the delivery date separately. Delivery times for
                individual products may vary.
            </p>
            <p>
                During the delivery of the goods, you must check the condition of the shipment. You have the right not
                to accept the shipment if you notice a shipment violation. Upon receipt of the shipment, it is
                considered that the shipment was delivered without damage.
            </p>
            <p>
                If incorrect delivery data is specified (recipient's name, address, postal code, contact phone number),
                the delivery date of the order may change. If the delivery data changes, please inform us as soon as
                possible by e-mail: eu-info@foherb.com
            </p>
            <p>
                You can get more information about the delivery of goods by tel. +37066137436 or e-mail by mail:
                eu-info@foherb.com
            </p>
            <p>
                The delivery of your goods is handled by the transport company UAB Linkera Group, which provides express
                delivery services in Lithuania and European countries.
            </p>
            <p>
                UAB "Linkera Group" mission - on time and assurances!
            </p>
        </div>
        <div style="text-align: center">
            <el-image :src="imgSrc" fit="contain"></el-image>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: 'ReturnGoods',
    data() {
        return {
            imgSrc: require('@/assets/images/wuliu.png')
        }
    },
    computed: {
        ...mapGetters([
            'language'
        ]),
    }
}
</script>

<style scoped lang="scss">
.ReturnGoods {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto
}
</style>
